<template>
  <div id="wrapper">
    <h1>{{ $t('provably_fair') }}</h1>
    <span v-html="$t('all_bitcoin.com_games')" />
    <p style='color: #818898;'>{{ $t('we_will_use') }}</p>

    <h2>{{ $t('step') }} 1</h2>
    <p>{{ $t('open_any_of') }}</p>
    <img style='margin-bottom: 1rem' src="@/assets/images/misc/gear_highlight.png" alt="gear icon">
    <p>{{ $t('a_popup_will') }}</p>
    <img class='big-image' :src="require(`@/assets/images/misc/custom_client_seed_1_${$i18n.locale}.png`)" alt="screenshot">
    <p>{{ $t('there_are_two') }}</p>
    <ol>
      <li>
        <strong class="white-text">{{ $t('custom_client_seed') }}</strong>
      </li>
      <li>
        <strong class="white-text">{{ $t('hash_of_servers') }}</strong>
      </li>
    </ol>
    <p>
      <span>
        <strong class="white-text">{{ $t('custom_client_seed') }} </strong>
      </span>
      <span>{{ $t('is_an_input') }}</span>
    </p>
    <p>
      <span>
        <strong class="white-text">{{ $t('hash_of_servers') }} </strong>
      </span>
      <span>{{ $t('is_the_hash') }}</span>
    </p>
    <p>{{ $t('now_enter_any') }}</p>

    <h2>{{ $t('step') }} 2</h2>
    <span v-html="$t('hit_the_spin')" />
    <img class='big-image' :src="require(`@/assets/images/misc/custom_client_seed_2_${$i18n.locale}.png`)" alt="screenshot">

    <h2>{{ $t('step') }} 3</h2>
    <img class='big-image' :src="require(`@/assets/images/misc/game_verification_1_${$i18n.locale}.png`)" alt="screenshot">
    <span v-html="$t('click_on_the')" />

    <h2>{{ $t('step') }} 4</h2>
    <img class='big-image' :src="require(`@/assets/images/misc/game_verification_2_${$i18n.locale}.png`)" alt="game verification">
    <span v-html="$t('once_you_validate')" />
  </div>
</template>

<script>
const cookies = require('js-cookie')
export default {
  mounted() {
    let urlParams = this.$route.query;
    if (urlParams.btag !== undefined) {
      cookies.set('btag', urlParams.btag, { expires: 30 })
    }
  },
  name: 'ProvablyFair',
  data: () => {
    return {
      userLang: this.$i18n.locale
    }
  }
}
</script>

<style scoped>
* {
  color: #b9bdc6;
}

span {
  bottom: -1px;
  position: relative;
}

.big-image {
  width: 100%;
  border-radius: 10px;
  margin: 10px 0 26px 0;
}

h1,
h2 {
  color: white;
}

h2 {
  margin-top: 3rem;
}

#wrapper {
  width: 700px;
  padding: 50px 80px;
  border-radius: 8px;
  background-color: #000;
  margin: 3rem auto 0 auto;
}

.white-text {
  color: white;
  font-size: 1rem;
}

@media only screen and (max-width: 768px) {
  #wrapper {
    width: 100%;
    padding: 50px 40px;
  }
}

@media only screen and (max-width: 575px) {
  img {
    width: 100%;
  }
}
</style>
